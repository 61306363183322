<template>
  <form>
    <div v-if="!has_password">
      <p class="text-center form-desc">
        It looks like you already have an account using {{ other_provider }}.
      </p>
      <div class="text-center mb-3">
        <a v-if="other_provider === 'LinkedIn'" class="btn btn-linked-in text-left" :href="SPENV.ACCOUNTS_API_URL + '/oauth/auth-request?provider=linkedin&action=login&' + qs"
           style="width:220px">
          <b-img-lazy :src="SPENV.S3_URL + '/images/logos/li-logo.png'" style="height:1.3em; vertical-align:text-bottom" class="mr-3" />Log in with LinkedIn
        </a>
        <a v-if="other_provider === 'Google'" class="btn btn-google text-left" :href="SPENV.ACCOUNTS_API_URL + '/oauth/auth-request?provider=google&action=login&' + qs"
           style="width:220px">
          <b-img-lazy :src="SPENV.S3_URL + '/images/logos/g-logo.png'" style="height:1.3em; vertical-align:text-bottom" class="mr-3" />Log in with Google
        </a>
      </div>
      <div class="text-center">
        <small>
          <router-link class="btn-link" :to="{ name: 'Login', query: query_params }">Back to Log in</router-link>
        </small>
      </div>
    </div>
    <div v-if="has_password">
      <h3 class="text-center font-weight-normal">
        Connect your account
      </h3>
      <p class="text-center">
        It looks like you already have a SolidProfessor account.
      </p>
      <p class="text-center">
        Enter your password to allow one-click log in with {{ provider }} in the
        future.
      </p>
      <div class="form-group">
        <label for="password" :class="{ 'text-danger': errors.has('password') }">
          Password
          <span v-show="errors.has('password')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
        </label>
        <input
          id="password"
          key="password"
          v-model="password"
          v-validate="'required'"
          data-vv-validate-on="input"
          :class="{ 'is-invalid': errors.has('password') }"
          class="form-control"
          value=""
          type="password"
          name="password">
      </div>
      <div class="form-group text-center">
        <button class="btn btn-success center-block" type="submit" name="link-submit"
                @click="validate">
          Continue
        </button>
      </div>
      <p class="text-center">
        <small><a class="btn-link forgot" :href="SPENV.APP_URL + '/iframes/reset-login.asp'" target="_blank">Forgot Your Password?</a></small>
      </p>
      <p class="text-center">
        <small>Don't link my account.
          <router-link class="btn-link" :to="{ name: 'Login', query: query_params }">Back to Log in</router-link>
        </small>
      </p>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
  name: 'OAuthLink',
  data() {
    return {
      provider: '',
      other_provider: '',
      has_password: null,
      query_params: {},
      qs: '',
      password: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    // make sure have necessary oauth state
    axios.get(process.env.SPENV.ACCOUNTS_API_URL + '/oauth/state').then(
      (response) => {
        next(vm => {
          vm.$data.provider = response.data.provider;
          vm.$data.other_provider = response.data.other_provider;
          vm.$data.has_password = response.data.has_password;
          if (response.data.affkey) {
            vm.$data.query_params.affkey = response.data.affkey;
          }
          if (response.data.campaign_id) {
            vm.$data.query_params.campaign_id = response.data.campaign_id;
          }
          if (response.data.gclid) {
            vm.$data.query_params.gclid = response.data.gclid;
          }
          if (response.data.return_url) {
            vm.$data.query_params.return_url = response.data.return_url;
          }
          vm.$data.qs = qs.stringify(vm.$data.query_params);
        });
      },
      () => {
        next({name: 'Login' });
      }
    );
  },
  methods: {
    validate(event) {
      event.preventDefault();
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // if form is valid, execute captcha which will trigger signup callback
            this.linkAccounts();
          } else {
          // form is invalid - do nothing
          }
        });
    },
    linkAccounts() {
      let body = {
        password: this.password
      };
      this.submitting = true;
      axios.post(this.SPENV.ACCOUNTS_API_URL + '/oauth/link', body).then(
        (response) => {
          this.submitting = false;
          this.loginRedirect(response);
        },
        (error) => {
          this.$emit('alert', error.response.data);
          // display error message
          this.submitting = false;
        }
      );
    }
  }
};
</script>
